.tracking-container {
  padding: 20px;
}

.tracking-row {
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  position: relative;
}

.tracking-data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.ship-order-scanner-qr {
  position: absolute;
  right: 10px;
  top: 22px;
}

.ship-order-scanner-qr span {
  color: rgb(37, 108, 184);
  cursor: pointer;
}
