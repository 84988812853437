/*list*/
.base-home_ecommerce-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10px !important;
  background-color: #ff9300;
}
.base-home_ecommerce-list-child {
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  background-color: white;
  text-decoration: underline;
  padding: 5px;
  margin: 10px 10px 0px 10px;
  justify-content: center;
}
.base-home_ecommerce-list-child:hover {
  cursor: pointer;
}
.base-home_ecommerce-list-child-img {
  width: 25px;
}
@media all and (max-width: 991px) {
  .base-home_ecommerce-list-child {
    width: calc(50% - 20px);
    font-size: 14px;
  }
  .base-home_ecommerce-list-child.full {
    width: 100%;
    font-size: 14px;
  }
}
