/*search*/
.base-home_search_container {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.base-home_search_title {
  background-color: white;
  width: fit-content;
  padding: 5px 40px;
  color: #ff9300 !important;
  font-weight: 500;
  height: 50px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #ff9300;
  transition: 0.5s;
}
.base-home_search_title.true {
  background-color: #ff9300;
  color: white !important;
}
.base-home_search_title:hover {
  background-color: #ff9300;
  color: white !important;
}
.base-home_search_title.search-title {
  border-radius: 25px 0px 0px 0px;
}
.base-home_search_title.tracking-title {
  border-radius: 0px 25px 0px 0px;
}
@media all and (max-width: 991px) {
  .base-home_search_title {
    padding: 5px 40px;
    color: white;
    font-weight: 500;
    height: 40px;
    font-size: 15px;
  }
  .base-home_search_title.search-title {
    border-radius: 20px 0px 0px 0px;
  }
  .base-home_search_title.tracking-title {
    border-radius: 0px 20px 0px 0px;
  }
}
.base-home_search_content {
  padding: 20px 30px;
  background-color: #ff9300;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.base-home_search-child {
  width: 126px;
  height: 40px;
  background-color: white;
  padding: 1px;
}
.base-home_search-child button {
  width: 100%;
  height: 100%;
  background-color: #ff9300;
  border: 1px solid white;
  color: white;
  font-size: 14px;
  font-weight: 700;
  transition: 0.5s;
}
.base-home_search-child button:hover {
  background-color: white;
  border: 1px solid #ff9300;
  color: #ff9300;
  border-radius: 20px;
}
.base-home_search-child button:focus {
  outline: none;
}
.base-home_search-full {
  height: 40px;
}
.base-home_search-full input {
  height: 100%;
  border: none;
  width: 100%;
}
  .base-home_search-full {
    display: flex;
    flex: 1;
  }
.base-home_search_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/*search option*/
.search-option-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.search-option_main-btn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.search-option_main-btn i {
  color: #ff9300;
}
.search-option_main-btn:hover {
  background-color: #ff9300;
  color: white;
}
.search-option_main-btn:hover i {
  color: white;
}
.search-option_element-container {
  width: calc(100% + 2px);
  position: absolute;
  top: 40px;
  left: -1px;
  background-color: white;
  border: 1px solid black;
  border-top: none;
  z-index: 100;
}
.search-option_base-display {
  display: flex;
  flex: 1;
  height: 40px;
  align-items: center;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}
.search-option_base-display.elm {
  border-top: 1px solid #ff9300;
}
.search-option_base-display.elm:hover {
  background-color: rgb(228, 224, 224);
}
.search-option_base-display.elm.last {
  background-color: lightgray;
}
.base-home_input {
  padding: 0px 5px;
  outline: none;
  border: none;
  min-width: 200px;
}

.tracking-scanner-qr {
  display: flex;
  flex-direction: row;
  background-color: white;
  flex: auto;
}

.tracking-scanner-qr span {
  margin: auto 5px;
  color: #ff9300;
  cursor: pointer;
}
