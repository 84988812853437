.base-home-container {
  position: relative;
  z-index: 2;
}
/*banner*/
.base-home {
  display: flex;
  flex-direction: column;
}
.base-home-wrapper {
  background-image: url(https://rs.jvscorp.jp/images/static_page_image/75ff94b7-97d3-4cce-bdcd-4ee89aac2f62.png);
  background-size: contain;
  height: 40vh;
  width: 100vw;
}
.base-home_baner-wrapper {
  display: flex;
  flex-direction: row;
}
.base-home_banner_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px 0px 0px 5px;
}
.base-home_banner_content img {
  width: 75%;
  align-self: center;
}
.base-home_banner_content-child {
  font-size: 3vw;
  width: 100%;
  z-index: 1;
  position: relative;
  color: white;
  font-family: Helvetica !important;
  font-style: italic;
  font-weight: 500;
  text-align: center;
}
.base-home_banner-title {
  font-size: 3vw;
  font-weight: 500;
  color: rgb(194, 63, 63);
}
@media all and (min-width: 992px) {
  .base-home_banner-title {
    font-size: 20px;
  }
  .base-home_banner_content-child {
    font-size: 25px;
  }
}
