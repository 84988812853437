.receiver-info_component_container {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  margin-bottom: 10px;
}
.receiver-info_component_container.even {
  background-color: #f5f5f5;
}
.receiver-info_component_container:hover {
  background-color: lightgray;
}
.re-info-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.re-info_cpn_btn {
  font-size: 14px;
  background-color: #ff9300 !important;
}
