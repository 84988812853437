.payment-detail-content {
  background-color: white;
}

.paid-payment {
  display: inline;
  border: solid 4px red;
  color: red;
  font-weight: 900;
  padding: 10px 20px;
  font-size: large;
  opacity: 0.5;
  position: fixed;
  top: 10vh;
  right: 10vw;
  transform: rotate(25deg);
}
