.nav-component_container {
  /* width: calc(100vw - 5px); */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 68px;
  align-items: center;
  padding-top: 4px;
  overflow-x: auto;
  overflow-y: hidden;
}

.nav-component_container::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
/* Track */
.nav-component_container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.nav-component_container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.nav-component_container::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.nav-component_container a {
  text-decoration: none;
  height: 100%;
  color: gray;
  width: fit-content;
}
.nav-component_container a:hover .nav-component_child {
  background-color: rgb(233, 232, 232);
}

.nav-component_child {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 0px 15px;
  font-size: 14px;
}
.nav-component_container .focus .nav-component_child {
  background-color: #0082dd !important;
  color: white;
}
.nav-name {
  white-space: nowrap;
}
