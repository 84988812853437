.purchase-account_component_container {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  margin-bottom: 10px;
}
.purchase-account_component_container.even {
  background-color: #f5f5f5;
}

.choose-purchase-account_edit-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.purchase-account-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}
.purchase-account_cpn_btn {
  font-size: 14px;
  background-color: #ff9300 !important;
}
