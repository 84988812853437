


.base-head-icon {
  font-size: 16;
  background-color: lightgray;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin: auto 5px;
}
.base-head-icon > i {
  font-size: 16;
  background-color: lightgray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.header-connection {
  display: flex;
  flex-direction: row;
}
.header-auth-button {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
}
.header-auth-button  > a {
  margin: 0 5px;
  font-weight: 500;
}
.login-button {
  background: rgb(64, 64, 64);
  padding: 2px 5px;
  border-radius: 5px;
  color: white;

}
