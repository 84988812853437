.login-google {
  margin-top: 15px;
  text-align: center;
  width: 100%;
}

.text-btn-login-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.text-btn-login-container > a {
  text-decoration: none;
  color: #0082dd;
  font-size: 15px;
  font-weight: 400;
}
.text-btn-login-container > a:hover {
  color: #d4000d;
}
.input-form-component {
  width: 100%;
}

/*login button*/
.login-btn {
  width: 100%;
  height: 48px;
  font-size: 16px;
  cursor: pointer;
  background-color: #5e5c5c;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  margin-top: 5px;
}
.login-btn:focus {
  background-color: lightgray;
  color: #acacac;
}
.login-btn:focus {
  outline: none;
}
.login-btn:hover::before {
  top: 0px;
  left: 0;
}

.login-btn-au {
  width: 100%;
  height: 40px;
  font-size: 16px;
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  margin-top: 5px;
  border: 1px solid lightgray;
  font-size: 14px;
  position: relative;
}
.login-btn-au_img {
  background-image: url(https://deo.shopeemobile.com/shopee/shopee-mobilemall-live-sg/assets/7b95007f3377150730bbb5d1ddb477d6.png);
  background-position: 83.9286% 5.15464%;
  background-size: 722.222% 638.889%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 10px;
}
.login-btn-au:focus {
  background-color: lightgray;
}
.login-btn-au:focus {
  outline: none;
}
.login-btn-au:hover::before {
  top: 0px;
  left: 0;
}

.login-form-note-text {
  color: gray;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 5px;
  margin-top: 15px;
  width: 100%;
}
