.choose-jvs_container {
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}
.choose-jvs_component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.choose-jvs_component.first {
  display: flex;
  flex-direction: row;
  transform: translateX(15%);
}

.choose-jvs_component img {
  width: 50%;
  object-fit: contain;
}
@media all and (min-width: 992px) {
  .choose-jvs_component img {
    width: 75%;
  }
}
.choose-jvs_child {
  background-color: #434343;
  padding: 5px 10px;
  width: 100%;
  font-size: 20px;
  margin: 5px;
  border-radius: 5px;
  color: white;
}
@media all and (max-width: 991px) {
  .choose-jvs_child {
    font-size: 15px;
  }
}
