.address-component_child {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 5px;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  margin-bottom: 10px;
  padding: 10px;
}
.dashboard_button_child.address {
  width: fit-content;
}

/*input*/
.address-common_input_wrapper {
  padding: 0;
  width: 100%;
  margin-bottom: 15px;
}
@media all and (min-width: 992px) {
  .address-common_input_wrapper {
    width: 45%;
  }
}

.address-common_input {
  position: relative;
  width: 100%;
  border: 2px solid lightgray;
  border-radius: 3px 3px 3px 3px;
}
.address-common_input > input {
  width: 100%;
  height: max(30px, min(calc((100vw - 991px) * 9999), 40px));
  padding-left: 10px;
  opacity: 0;
  border: none;
  border-radius: 3px 3px 3px 3px;
}
.address-common_input > input:focus {
  outline: none;
}
.address-common_input.focus-input {
  border-color: #009250;
}
.address-common_input-title {
  position: absolute;
  top: 5%;
  left: 20px;
  background-color: white;
  translate: 1s;
  color: #42494d;
  font-size: 14px;
  font-weight: 500;
  color: lightgray;
  transition: 0.5s;
}
.address-common_input.focus-input .address-common_input-title {
  top: -50%;
  color: #42494d;
}
.address-common_input.focus-input > input {
  opacity: 1;
}
.address-common_input.validate-input > input {
  opacity: 1;
}
.address-common_input.validate-input .address-common_input-title {
  top: -50%;
  color: #42494d;
}

/*button*/
.add-address_btn_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dashboard_button_child.address-add {
  width: fit-content;
}
