
.tracking-detail-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px auto;
  width: 90px;
}
.tracking-detail-child > i {
  font-size: max(40px, min(calc((100vw - 991px) * 9999), 50px));
  color: lightblue;
}
.tracking-detail-child > div {
  width: 100%;
  font-size: max(11px, min(calc((100vw - 991px) * 9999), 14px));
  color: lightgray;
}
.tracking-detail-child.active > i {
  color: #2b80dd;
}
.tracking-detail-child.active > div {
  color: black;
}

/*tracking detail*/
.tracking-detail-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  background-color: rgb(241, 240, 237);
}

/*tracking status*/
.tracking-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 2vw;
}

/*header*/
.tracking-component-header {
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid lightgray;
  background-image: linear-gradient(white, lightgray);
  text-align: start;
  padding-left: 15px;
}

/*wrapper*/
.tracking-wrapper {
  width: fit-content;
  border: 1px solid lightgray;
  font-size: 14px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
}

.tracking-content {
  display: flex;
  flex-direction: row;
}

.vn-tracking-guide {
  width: 300px;
  text-wrap: wrap;
  overflow: hidden;
  font-style: italic;
  font-size: smaller;
  padding: 0 10px;
}


.vn-tracking-guide>span {
  color: blue;
  cursor: pointer;
}
