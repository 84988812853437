.productorder-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  background-color: white;
  max-height: 90vh;
  max-width: 100vw;
  margin: auto;
}

.productorder-info {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 10px;
}

.productorder-row {
  padding: 4px 5px;
}

.refshiporder {
  color: blue;
  cursor: pointer;
  font-style: italic;
  text-align: center;
}
