.dashboard-container {
  position: relative;
  min-height: 100vh;
  z-index: 0;
  background-color: white;
}

/*title*/
.dashboard_title {
  text-align: center;
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  padding-top: 15px;
  border-bottom: 1px solid lightgray;
}
@media all and (min-width: 992px) {
  .dashboard_title {
    font-size: 24px;
    background-color: white;
    padding-bottom: 10px;
    padding-top: 80px;
  }
}

/*component*/
.dashboard_component {
  border-radius: 5px;
  padding: 20px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px;
  cursor: pointer;
  box-shadow: gray 0px 4px 5px 0px;
  height: 116px;
}
.dashboard_component.product {
  background: linear-gradient(45deg, rgb(213, 0, 249), rgb(255, 160, 0));
}
.dashboard_component.ship {
  background: linear-gradient(45deg, rgb(255, 111, 0), rgb(255, 202, 40));
}
.dashboard_component.address {
  background: linear-gradient(45deg, rgb(250, 166, 55), rgb(8, 198, 219));
}
.dashboard_component.purchase-account {
  background: linear-gradient(45deg, rgb(0, 151, 167), rgb(178, 255, 89));
}
.dashboard_component div,
.dashboard_component span {
  color: white;
  font-size: 20px;
}
.dashboard_component i {
  color: white;
  font-size: 35px;
}
.dashboard_component div {
  color: white;
}
.dashboard_component_child.last {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media all and (max-width: 991px) {
  .dashboard_component {
    height: 90px;
    padding: 12px;
  }
  .dashboard_component i {
    font-size: 25px;
  }
  .dashboard_component div,
  .dashboard_component span {
    font-size: 18px;
  }
}

/*table*/
.dashboard_table {
  border-radius: 5px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
  box-shadow: gray 0px 4px 5px 0px;
}
.dashboard_table > div,
.dashboard_table > span {
  color: white;
  font-size: 20px;
}
.dashboard_table > i {
  color: white;
  font-size: 35px;
}
.dashboard_table > div {
  color: black;
}
.dashboard_table_child.last {
  margin-top: 5px;
  width: 100%;
  overflow: auto;
}

@media all and (max-width: 991px) {
  .dashboard_table {
    min-height: 90px;
    padding: 12px;
    max-height: 100vh;
  }
  .dashboard_table > i {
    font-size: 25px;
  }
  .dashboard_table > div,
  .dashboard_table > span {
    font-size: 18px;
  }
}

.dashboard_table_child_component {
  width: 100%;
  min-width: 700px;
  font-weight: 400 !important;
}
.dashboard_table_child_component tr,
.dashboard_table_child_component th,
.dashboard_table_child_component td {
  font-weight: 400;
  border: 1px solid gray;
  text-align: center;
  font-size: 14px;
  text-align: center;
}
.dashboard_table_child_component tr.even {
  background-color: rgb(238, 237, 237);
}
.dashboard_table_child_header th {
  background-color: #f5f5f5;
  color: #ff2c00;
  font-weight: 500 !important;
}
