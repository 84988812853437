.product-order_tool-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: -15px;
}
@media all and (max-width: 991px) {
  .product-order_tool-bar {
    justify-content: center;
  }
}
.product-order_tool-bar_btn {
  outline: none;
  border: none;
  margin: 10px;
}
.product-order_tool-bar_btn:focus {
  outline: none;
  border: none;
}
.list-product-component_img_upload {
  width: 100px;
  height: 75px;
  object-fit: contain;
  cursor: pointer;
}
