.service-base-home {
  border-radius: 10px;
  border: 2px solid black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
/*title*/
.service-base-home_title {
  background-color: black;
  color: #ff9300;
  padding: 8px 20px;
  font-size: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*main*/
.service-base-home_main {
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.service-base-home_main_child {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
}
.service_main_child_img {
  width: 80px;
  height: 80px;
}
@media all and (max-width: 991px) {
  .service_main_child_img {
    width: 60px;
    height: 60px;
  }
  .service-base-home_main_child {
    font-size: 15px;
    padding: 5px;
    margin: 10px;
  }
  .service-base-home_title {
    font-size: 20px;
  }
}
