.note-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    width: max(400px, 20vw);
    max-width: 90vw;
}

.note-title {
    background-color: #256cb8;
    color: white;
    text-align: center;
    padding: 2px;
    margin: 2px 0;
}

.note-input {
    border-radius: 5px;
    border: 1px solid lightgray;
    min-height: 80px;
    resize: none;
}

.note-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.note-date {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.note-line {
    display: flex;
    flex-direction: row;
    flex: 1;
    border-bottom: 1px solid black;
    height: 100%;
    transform: translateY(-50%);
    color: white;
    font-size: 1px;
}

.note-item {
    width: 100%;
    height: fit-content;
    padding: 10px;
    display: flex;
    flex-direction: row;
    background-color: #f5f5f5;
    border-bottom: 1px dashed gray;
}
.note-item-avt {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: lightgray;
}
.note-item-avt img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.note-detail {
    margin: 0px 5px;
    display: flex;
    flex: 1;
    flex-direction: column;
}
.note-text-inp {
    width: 90%;
    height: 100px;
    resize: none;
}
.note-btn {
    margin: 10px 0px 10px 0px;
    background-color: #0090ed;
    color: white;
    padding: 2px 15px;
    border-radius: 5px;
    border-color: gray;
}
.note-btn {
    background-color: #256cb8;
    border: none;
    outline: none;
    color: white;
    font-weight: 500;
    width: 100%;
    margin: 5px auto 5px auto;
}

.note-btn:focus {
    border: none;
    outline: none;
}

.note-btn:active {
    transform: translateY(1px);
}
