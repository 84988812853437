.shiporder-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  background-color: white;
  max-height: 80vh;
  max-width: 100vw;
  margin: auto;
  overflow: auto;
}

.shiporder-info {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 10px;
}

.shiporder-row {
  padding: 4px 5px;
}
.refproduct-table {
  width: max(40vw, 600px);
  max-width: 90vw ;
}
.refproduct-table > span {
  text-align: center;
}

.shiporder-vn-tracking-guide {
  width: 360px;
  text-wrap: wrap;
  overflow: hidden;
  font-style: italic;
  font-size: smaller;
  padding: 0 10px;
}

.shiporder-vn-tracking-guide>span {
  color: blue;
  cursor: pointer;
}
